<template>
  <div class="flex flex-col">
    <select
      v-bind="$attrs"
      :name="name"
      :value="modelValue"
      @change="$emit('update:modelValue', ($event.target as any).value)"
    >
      <option
        disabled
        value=""
      >
        {{ $props.emptyText }} {{ $props.optional ? '(optional)' : '' }}
      </option>

      <template v-if="hasValues">
        <option
          v-for="(option, i) in ($props.options as any)"
          :key="i"
          :value="option.value"
        >
          {{ option.label }}
        </option>
      </template>

      <template v-else>
        <option
          v-for="(option, i) in $props.options"
          :key="i"
        >
          {{ option }}
        </option>
      </template>
    </select>

    <span
      v-if="error"
      class="text-red-600 text-sm"
    >{{ error }}</span>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';

const props = withDefaults(
  defineProps<{
    name: string;
    modelValue: string | number | null | undefined;
    error?: string;
    options: string[] | undefined | ({ value: string | number; label: string })[];
    optional?: boolean;
    emptyText?: string;
  }>(),
  {
    error: undefined,
    options: () => [],
    /* eslint-disable no-null/no-null */
    modelValue: null,
    optional: false,
    emptyText: 'Select one',
  },
);

defineEmits(['update:modelValue']);

const hasValues = computed(() => {
  return props.options.findIndex((option) => {
    return typeof option === 'object' &&
      'value' in option;
  }) !== -1;
});
</script>

<script lang="ts">
export default {
  inheritAttrs: false,
};
</script>

<style lang="css" scoped></style>
